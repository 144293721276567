<template>
  <div class="widget-form-container">
    <div v-if="data.list.length == 0" class="form-empty">{{$t('FORMS.DESCRIPTION.CONTAINER_EMPTY')}}</div>
    <b-form :size="data.config.size" label-suffix=":" :label-position="data.config.labelPosition" :label-width="data.config.labelWidth + 'px'">

      <draggable class=""
        v-model="data.list"
        v-bind="{group:'people', ghostClass: 'ghost',animation: 200, handle: '.drag-widget'}"
        @end="handleMoveEnd"
        @add="handleWidgetAdd"
      >

        <transition-group name="fade" tag="div" class="widget-form-list st-page">
          <template v-for="(element, index) in data.list">
            <template v-if="element.type == 'grid'">
                <b-row class="widget-col widget-view" v-if="element && element.key" :key="element.key"
                  type="flex"
                  :class="{active: selectWidget.key == element.key}"
                  :gutter="element.options.gutter ? element.options.gutter : 0"
                  :justify="element.options.justify"
                  :align="element.options.align"
                  @click="handleSelectWidget(index)">
                  <b-col v-for="(col, colIndex) in element.columns" :key="colIndex" :cols="col.span ? col.span : 0">

                      <draggable
                        v-model="col.list"
                        :no-transition-on-drag="true"
                        v-bind="{group:'people', ghostClass: 'ghost',animation: 200, handle: '.drag-widget'}"
                        @end="handleMoveEnd"
                        @add="handleWidgetColAdd($event, element, colIndex)"
                      >
                        <transition-group name="fade" tag="div" class="widget-col-list">
                          <template v-for="(el, i) in col.list">
                            <widget-form-item
                              :key="el.key"
                              v-if="el.key"
                              :element="el"
                              :select.sync="selectWidget"
                              :index="i"
                              :data="col">
                            </widget-form-item>
                          </template>

                        </transition-group>

                      </draggable>
                  </b-col>
                  <div class="widget-view-action widget-col-action" v-if="selectWidget.key == element.key">

                    <i class="fas fa-trash" @click.stop="handleWidgetDelete(index)"></i>
                  </div>

                  <div class="widget-view-drag widget-col-drag" v-if="selectWidget.key == element.key">
                    <i class="fas fa-arrows-alt drag-widget"></i>
                  </div>
                </b-row>
            </template>
            <template v-else-if="element.type === 'inline'">
              <div class="widget-inline widget-view" v-if="element && element.key" :key="element.key"
                  :class="{active: selectWidget.key == element.key}"
                  @click="handleSelectWidget(index)">
                  <div class="widget-inline-content">
                    <draggable
                      v-model="element.list"
                      :no-transition-on-drag="true"
                      v-bind="{group:'people', ghostClass: 'ghost',animation: 200, handle: '.drag-widget'}"
                      @end="handleMoveEnd"
                      @add="handleWidgetComponentAdd($event, element, 'inline')"
                    >
                    <transition-group name="fade" tag="div" class="widget-inline-list">
                      <template v-for="(el, elIndex) in element.list">
                        <widget-form-item
                          :key="el.key"
                          v-if="el && el.key"
                          :element="el"
                          :select.sync="selectWidget"
                          :index="elIndex"
                          :data="element">
                        </widget-form-item>
                      </template>
                    </transition-group>
                    </draggable>
                    <div class="widget-view-action widget-inline-action" v-if="selectWidget.key == element.key">
                      <i class="fas fa-trash" @click.stop="handleWidgetDelete(index)"></i>
                    </div>

                    <div class="widget-view-drag widget-inline-drag" v-if="selectWidget.key == element.key">
                      <i class="fas fa-arrows-alt drag-widget"></i>
                    </div>
                </div>
              </div>
            </template>
            <template v-else-if="element.type === 'section'">
              <div class="widget-view" v-if="element && element.key" :key="element.key"
                  type="flex"
                  :class="{active: selectWidget.key == element.key}"
                  @click="handleSelectWidget(index)">
                  <b-card class="st-section mt-6">
                      <div class="st-section-header">
                        {{ element.options.title }}
                      </div>
                      <draggable
                        v-model="element.list"
                        :no-transition-on-drag="true"
                        v-bind="{group:'people', ghostClass: 'ghost',animation: 200, handle: '.drag-widget'}"
                        @end="handleMoveEnd"
                        @add="handleWidgetComponentAdd($event, element, 'section')"
                      >
                        <transition-group name="fade" tag="div" class="widget-col-list st-section-body">
                          <template v-for="(el, elIndex) in element.list">
                            <template v-if="el.type == 'grid'">
                                <b-row class="widget-col widget-view" v-if="el && el.key" :key="el.key"
                                  type="flex"
                                  :class="{active: selectWidget.key == el.key}"
                                  :gutter="el.options.gutter ? el.options.gutter : 0"
                                  :justify="el.options.justify"
                                  :align="el.options.align"
                                  @click.stop="handleSelectSectionWidget(index, elIndex)">
                                  <b-col v-for="(col, colIndex) in el.columns" :key="colIndex" :cols="col.span ? col.span : 0">

                                      <draggable
                                        v-model="col.list"
                                        :no-transition-on-drag="true"
                                        v-bind="{group:'people', ghostClass: 'ghost',animation: 200, handle: '.drag-widget'}"
                                        @end="handleMoveEnd"
                                        @add="handleSectionWidgetColAdd($event, element, elIndex, el, colIndex)"
                                      >
                                        <transition-group name="fade" tag="div" class="widget-col-list">
                                          <template v-for="(elc, j) in col.list">
                                            <widget-form-item
                                              :key="elc.key"
                                              v-if="elc.key"
                                              :element="elc"
                                              :select.sync="selectWidget"
                                              :index="j"
                                              :data="col">
                                            </widget-form-item>
                                          </template>

                                        </transition-group>

                                      </draggable>
                                  </b-col>
                                  <div class="widget-view-action widget-col-action" v-if="selectWidget.key == el.key">
                                    <i class="fas fa-trash" @click.stop="handleSectionWidgetDelete(index, elIndex)"></i>
                                  </div>

                                  <div class="widget-view-drag widget-col-drag" v-if="selectWidget.key == el.key">
                                    <i class="fas fa-arrows-alt drag-widget"></i>
                                  </div>
                                </b-row>
                            </template>
                            <widget-form-item
                              :key="el.key"
                              v-else-if="el && el.key"
                              :element="el"
                              :select.sync="selectWidget"
                              :index="elIndex"
                              :data="element">
                            </widget-form-item>
                          </template>

                        </transition-group>

                      </draggable>
                  </b-card>
                  <div class="widget-view-action widget-col-action" v-if="selectWidget.key == element.key">

                    <i class="fas fa-trash" @click.stop="handleWidgetDelete(index)"></i>
                  </div>

                  <div class="widget-view-drag widget-col-drag" v-if="selectWidget.key == element.key">
                    <i class="fas fa-arrows-alt drag-widget"></i>
                  </div>
                </div>
            </template>
            <template v-else>
              <widget-form-item v-if="element && element.key"  :key="element.key" :element="element" :select.sync="selectWidget" :index="index" :data="data"></widget-form-item>
            </template>
          </template>
        </transition-group>
      </draggable>
    </b-form>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import WidgetFormItem from './WidgetFormItem'

export default {
  inheritAttrs: false,
  components: {
    Draggable,
    WidgetFormItem
  },
  props: ['data', 'select'],
  data () {
    return {
      selectWidget: this.select
    }
  },
  mounted () {
    document.body.ondrop = function (event) {
      let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      if (isFirefox) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  },
  methods: {
    handleMoveEnd ({newIndex, oldIndex}) {
    },
    handleSelectWidget(index) {
      this.selectWidget = this.data.list[index];
    },
    handleSelectSectionWidget(sectionIndex, sectionElementIndex) {
      this.selectWidget = this.data.list[sectionIndex].list[sectionElementIndex];
    },
    handleWidgetAdd (evt) {
      const newIndex = evt.newIndex
      const to = evt.to

      // Add a unique key to the element dragged to the container
      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999);
      this.$set(this.data.list, newIndex, {
        ...this.data.list[newIndex],
        options: {
          ...this.data.list[newIndex].options,
        },
        key,
        // Binding key
        model: this.data.list[newIndex].type + '_' + key,
        name: this.data.list[newIndex].type + '_' + key,
        rules: []
      })

      if (this.data.list[newIndex].type === 'radio' || this.data.list[newIndex].type === 'checkbox' || this.data.list[newIndex].type === 'select') {
        this.$set(this.data.list, newIndex, {
          ...this.data.list[newIndex],
          options: {
            ...this.data.list[newIndex].options,
            options: this.data.list[newIndex].options.options.map(item => ({
              ...item
            }))
          }
        })
      }

      if (this.data.list[newIndex].type === 'grid') {
        this.$set(this.data.list, newIndex, {
          ...this.data.list[newIndex],
          columns: this.data.list[newIndex].columns.map(item => ({...item}))
        })
      }
      this.selectWidget = this.data.list[newIndex]
    },
    handleWidgetColAdd ($event, row, colIndex) {
      const newIndex = $event.newIndex
      const oldIndex = $event.oldIndex
      const item = $event.item

      // Prevent nested drag and drop of layout elements
      if (item.className.indexOf('data-grid') >= 0) {

        // If it is the dragged element in the list, it needs to be restored to its original position
        item.tagName === 'DIV' && this.data.list.splice(oldIndex, 0, row.columns[colIndex].list[newIndex])

        row.columns[colIndex].list.splice(newIndex, 1)

        return false;
      }
      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)

      this.$set(row.columns[colIndex].list, newIndex, {
        ...row.columns[colIndex].list[newIndex],
        options: {
          ...row.columns[colIndex].list[newIndex].options,
        },
        key,
        // Binding key
        model: row.columns[colIndex].list[newIndex].type + '_' + key,
        name: row.columns[colIndex].list[newIndex].type + '_' + key,
        rules: []
      })

      if (row.columns[colIndex].list[newIndex].type === 'radio' || row.columns[colIndex].list[newIndex].type === 'checkbox' || row.columns[colIndex].list[newIndex].type === 'select') {
        this.$set(row.columns[colIndex].list, newIndex, {
          ...row.columns[colIndex].list[newIndex],
          options: {
            ...row.columns[colIndex].list[newIndex].options,
            options: row.columns[colIndex].list[newIndex].options.options.map(item => ({
              ...item
            }))
          }
        })
      }

      this.selectWidget = row.columns[colIndex].list[newIndex]
    },
    handleSectionWidgetColAdd($event, section, widgetId, row, colIndex) {
      const newIndex = $event.newIndex
      const oldIndex = $event.oldIndex
      const item = $event.item;
      // Prevent nested drag and drop of layout elements
      if (item.className.indexOf('data-grid') >= 0) {

        // If it is the dragged element in the list, it needs to be restored to its original position
        item.tagName === 'DIV' && this.data.list.splice(oldIndex, 0, row.columns[colIndex].list[newIndex])

        row.columns[colIndex].list.splice(newIndex, 1)

        return false;
      }

      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)

      this.$set(section.list[widgetId].columns[colIndex].list, newIndex, {
        ...section.list[widgetId].columns[colIndex].list[newIndex],
        options: {
           ...section.list[widgetId].columns[colIndex].list[newIndex].options,
        },
        key,
        // Binding key
        model: section.list[widgetId].columns[colIndex].list[newIndex].type + '_' + key,
        name: section.list[widgetId].columns[colIndex].list[newIndex].type + '_' + key,
        rules: []
      })

      if (section.list[widgetId].columns[colIndex].list[newIndex].type === 'radio' || section.list[widgetId].columns[colIndex].list[newIndex].type === 'checkbox' || section.list[widgetId].columns[colIndex].list[newIndex].type === 'select') {
        this.$set(section.list[widgetId].columns[colIndex].list, newIndex, {
          ...section.list[widgetId].columns[colIndex].list[newIndex],
          options: {
            ...section.list[widgetId].columns[colIndex].list[newIndex].options,
            options: section.list[widgetId].columns[colIndex].list[newIndex].options.options.map(item => ({
              ...item
            }))
          }
        })
      }

      this.selectWidget = section.list[widgetId].columns[colIndex].list[newIndex];
    },
    handleWidgetComponentAdd($event, element, type) {
      const newIndex = $event.newIndex;
      const oldIndex = $event.oldIndex;
      const item = $event.item;
      const excludedFromInline = [
        "grid",
        "section",
        "inline",
        "application-detail-form",
        "beneficiary-list",
        "applicant-form",
        "real-estate-list",
        "decision-list",
        "attachments",
      ];

      if (type === 'inline' && excludedFromInline.includes(element.list[newIndex].type)) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999);

      this.$set(element.list, newIndex, {
        ...element.list[newIndex],
        options: {
          ...element.list[newIndex].options,
        },
        key,
        // Binding key
        model: element.list[newIndex].type + '_' + key,
        name: element.list[newIndex].type + '_' + key,
        rules: []
      })

      if (element.list[newIndex].type === 'radio' || element.list[newIndex].type === 'checkbox' || element.list[newIndex].type === 'select') {
        this.$set(element.list, newIndex, {
          ...element.list[newIndex],
          options: {
            ...element.list[newIndex].options,
            options: element.list[newIndex].options.options.map(item => ({
              ...item
            }))
          }
        })
      }

      this.selectWidget = element.list[newIndex];
    },
    handleWidgetDelete (index) {
      if (this.data.list.length - 1 === index) {
        if (index === 0) {
          this.selectWidget = {}
        } else {
          this.selectWidget = this.data.list[index - 1]
        }
      } else {
        this.selectWidget = this.data.list[index + 1]
      }

      this.$nextTick(() => {
        this.data.list.splice(index, 1)
      })
    },
    handleSectionWidgetDelete(sectionIndex, sectionElementIndex) {
    if (this.data.list[sectionIndex].list.length - 1 === sectionElementIndex) {
        if (sectionElementIndex === 0) {
          this.selectWidget = {}
        } else {
          this.selectWidget = this.data.list[sectionIndex].list[sectionElementIndex - 1]
        }
      } else {
        this.selectWidget = this.data.list[sectionIndex].list[sectionElementIndex + 1]
      }

      this.$nextTick(() => {
        this.data.list[sectionIndex].list.splice(sectionElementIndex, 1);
      });
    },
  },
  watch: {
    select (val) {
      this.selectWidget = val;
    },
    selectWidget: {
      deep: true,
      handler (val) {
        this.$emit('update:select', val);
      },
    }
  }
}
</script>
