<template>
  <div v-if="show">
    <b-form label-position="top">
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.MODEL')"
        v-if="!isLayoutWidget"
      >
        <b-form-input disabled v-model="data.model"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.NAME')"
        v-if="!isLayoutWidget && !isCustomComponent"
      >
        <b-form-input v-model="data.name"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.LABEL')"
        v-if="!isLayoutWidget"
      >
        <b-form-input v-model="data.label"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.HIDE_LABEL')"
        v-if="!isLayoutWidget"
      >
        <b-form-checkbox v-model="data.options.hideLabel" switch size="lg">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.SHOW_TOOLTIP')"
        v-if="!isLayoutWidget&& !isCustomComponent && !data.options.hideLabel"
      >
        <b-form-checkbox v-model="data.options.showTooltip" switch size="lg">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.TOOLTIP_TEXT')"
        v-if="data.options.showTooltip"
      >
        <b-form-input v-model="data.toolTipText"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.ROWS')"
        v-if="data.type === 'textarea'"
      >
        <b-form-input v-model="data.options.rows"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.MAX_ROWS')"
        v-if="data.type === 'textarea'"
      >
        <b-form-input v-model="data.options.maxRows"></b-form-input>
      </b-form-group>
      <b-form-group v-if="!isLayoutWidget">
        <b-form-checkbox v-model="data.isConditioned">
            {{ $t("FORMS.CONFIG.WIDGET.IS_CONDITIONED")}}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.CONDITION')"
        v-if="data.isConditioned"
      >
        <b-form-input v-model="data.condition"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.WIDTH')"
        v-if="Object.keys(data.options).indexOf('width') >= 0"
      >
        <b-form-input v-model="data.options.width"></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.HEIGHT')"
        v-if="Object.keys(data.options).indexOf('height') >= 0"
      >
        <b-form-input v-model="data.options.height"></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.SIZE')"
        v-if="Object.keys(data.options).indexOf('size') >= 0"
      >
        {{ $t("FORMS.CONFIG.WIDGET.WIDTH") }}
        <b-form-input
          style="width: 90px"
          type="number"
          v-model.number="data.options.size.width"
        ></b-form-input>
        {{ $t("FORMS.CONFIG.WIDGET.HEIGHT") }}
        <b-form-input
          style="width: 90px"
          type="number"
          v-model.number="data.options.size.height"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.PLACEHOLDER')"
        v-if="
          Object.keys(data.options).indexOf('placeholder') >= 0 &&
          (data.type != 'time' || data.type != 'date')
        "
      >
        <b-form-input v-model="data.options.placeholder"></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.LAYOUT')"
        v-if="Object.keys(data.options).indexOf('inline') >= 0"
      >
        <b-form-radio-group
          v-model="data.options.inline"
          buttons
          button-variant="outline-primary"
          size="sm"
        >
          <b-form-radio :value="false">{{
            $t("FORMS.CONFIG.WIDGET.BLOCK")
          }}</b-form-radio>
          <b-form-radio :value="true">{{
            $t("FORMS.CONFIG.WIDGET.INLINE")
          }}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.SHOW_INPUT')"
        v-if="Object.keys(data.options).indexOf('showInput') >= 0"
      >
        <b-form-checkbox
          v-model="data.options.showInput"
          switch
          size="lg"
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.MIN')"
        v-if="Object.keys(data.options).indexOf('min') >= 0"
      >
        <b-form-input
          type="number"
          v-model="data.options.min"
          :min="0"
          :max="100"
          :step="1"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.MAX')"
        v-if="Object.keys(data.options).indexOf('max') >= 0"
      >
        <b-form-input
          type="number"
          v-model="data.options.max"
          :min="0"
          :max="100"
          :step="1"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.STEP')"
        v-if="Object.keys(data.options).indexOf('step') >= 0"
      >
        <b-form-input
          type="number"
          v-model="data.options.step"
          :min="0"
          :max="100"
          :step="1"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.FILTERABLE')"
        v-if="data.type == 'select'"
      >
        <b-form-checkbox
          v-model="data.options.filterable"
          switch
          size="lg"
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.ALLOW_HALF')"
        v-if="Object.keys(data.options).indexOf('allowHalf') >= 0"
      >
        <b-form-checkbox v-model="data.options.allowHalf" switch size="lg">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.SHOW_ALPHA')"
        v-if="Object.keys(data.options).indexOf('showAlpha') >= 0"
      >
        <b-form-checkbox v-model="data.options.showAlpha" switch size="lg">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.SHOW_LABEL')"
        v-if="Object.keys(data.options).indexOf('showLabel') >= 0"
      >
        <b-form-checkbox v-model="data.options.showLabel" switch size="lg">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        :label="`${$t('FORMS.CONFIG.WIDGET.FONT_SIZE')} (px)`"
        v-if="data.type === 'text'"
      >
        <b-form-select
          v-model="data.options.fontSize"
          :options="fontSizeList"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.IS_BOLD')"
        v-if="data.type === 'text'"
      >
        <b-form-checkbox v-model="data.options.isBold" switch size="lg">
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.OPTION')"
        v-if="Object.keys(data.options).indexOf('options') >= 0"
      >
        <template>
          <template
            v-if="
              data.type == 'radio' ||
              (data.type == 'select' && !data.options.multiple)
            "
          >
            <b-form-radio-group v-model="data.options.defaultValue">
              <draggable
                tag="ul"
                :list="data.options.options"
                v-bind="{
                  group: { name: 'options' },
                  ghostClass: 'ghost',
                  handle: '.drag-item',
                }"
                handle=".drag-item"
              >
                <li v-for="(item, index) in data.options.options" :key="index">
                  <b-form-radio :value="item.value" style="margin-right: 5px">
                    <b-form-input
                      :style="{
                        width: data.options.showLabel ? '90px' : '180px',
                      }"
                      size="mini"
                      v-model="item.value"
                    ></b-form-input>
                    <b-form-input
                      style="width: 90px"
                      size="mini"
                      v-if="data.options.showLabel"
                      v-model="item.label"
                    ></b-form-input>
                    <!-- <input v-model="item.value"/> -->
                  </b-form-radio>
                  <i
                    class="drag-item"
                    style="font-size: 16px; margin: 0 5px; cursor: move"
                    ><i class="fas fa-bars"></i
                  ></i>
                  <i
                    @click="handleOptionsRemove(index)"
                    class="fas fa-minus-circle text-danger ml-5"
                  ></i>
                </li>
              </draggable>
            </b-form-radio-group>
          </template>

          <template
            v-if="
              data.type == 'checkbox' ||
              (data.type == 'select' && data.options.multiple)
            "
          >
            <b-form-checkbox-group v-model="data.options.defaultValue">
              <draggable
                tag="ul"
                :list="data.options.options"
                v-bind="{
                  group: { name: 'options' },
                  ghostClass: 'ghost',
                  handle: '.drag-item',
                }"
                handle=".drag-item"
              >
                <li v-for="(item, index) in data.options.options" :key="index">
                  <b-form-checkbox
                    :value="item.value"
                    style="margin-right: 5px"
                  >
                    <b-form-input
                      :style="{
                        width: data.options.showLabel ? '90px' : '180px',
                      }"
                      size="mini"
                      v-model="item.value"
                    ></b-form-input>
                    <b-form-input
                      style="width: 90px"
                      size="mini"
                      v-if="data.options.showLabel"
                      v-model="item.label"
                    ></b-form-input>
                  </b-form-checkbox>
                  <i
                    class="drag-item"
                    style="font-size: 16px; margin: 0 5px; cursor: move"
                    ><i class="fas fa-bars"></i
                  ></i>
                  <i
                    @click="handleOptionsRemove(index)"
                    class="fas fa-minus-circle text-danger ml-5"
                  ></i>
                </li>
              </draggable>
            </b-form-checkbox-group>
          </template>
          <div style="margin-left: 22px">
            <b-button variant="primary" size="sm" @click="handleAddOption">{{
              $t("FORMS.ACTIONS.ADD_OPTION")
            }}</b-button>
          </div>
        </template>
      </b-form-group>

      <b-form-group
        :label="$t('FORMS.CONFIG.WIDGET.DEFAULT_VALUE')"
        v-if="
          Object.keys(data.options).indexOf('defaultValue') >= 0 &&
          (data.type === 'textarea' ||
            data.type === 'input' ||
            data.type === 'switch' ||
            data.type === 'text' ||
            data.type === 'html')
        "
      >
        <b-form-textarea
          v-if="data.type === 'textarea'"
          :rows="5"
          v-model="data.options.defaultValue"
        ></b-form-textarea>
        <b-form-input
          v-if="data.type === 'input'"
          v-model="data.options.defaultValue"
        ></b-form-input>
        <b-form-checkbox
          v-if="data.type === 'switch'"
          switch
          size="lg"
          v-model="data.options.defaultValue"
        ></b-form-checkbox>
        <b-form-input
          v-if="data.type === 'text'"
          v-model="data.options.defaultValue"
        ></b-form-input>
        <vue-editor
          v-if="data.type === 'html'"
          v-model="data.options.defaultValue"
          :style="{width: data.options.width}"
        >
        </vue-editor>
      </b-form-group>

      <template v-if="data.type == 'date'">
        <b-form-group
          :label="$t('FORMS.CONFIG.WIDGET.PLACEHOLDER')"
          v-if="
            (!data.options.isRange && data.type == 'time') ||
            (data.type != 'time' &&
              data.options.type != 'datetimerange' &&
              data.options.type != 'daterange')
          "
        >
          <b-form-input v-model="data.options.placeholder"></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('FORMS.CONFIG.WIDGET.DEFAULT_VALUE')"
          v-if="
            data.type == 'time' &&
            Object.keys(data.options).indexOf('isRange') >= 0
          "
        >
          <b-form-datepicker
            key="1"
            style="width: 100%"
            v-model="data.options.defaultValue"
          >
          </b-form-datepicker>
        </b-form-group>
      </template>

      <template v-if="data.type == 'imgupload'">
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.LIMIT')">
          <b-form-input
            type="number"
            v-model.number="data.options.length"
          ></b-form-input>
        </b-form-group>
        <template>
          <b-form-group
            :label="$t('FORMS.CONFIG.WIDGET.IMAGE_ACTION')"
            :required="true"
          >
            <b-form-input v-model="data.options.action"></b-form-input>
          </b-form-group>
        </template>
      </template>

      <template v-if="data.type == 'grid'">
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.GUTTER')">
          <b-form-input
            type="number"
            v-model.number="data.options.gutter"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.COLUMN_OPTION')">
          <draggable
            tag="ul"
            :list="data.columns"
            v-bind="{
              group: { name: 'options' },
              ghostClass: 'ghost',
              handle: '.drag-item',
            }"
            handle=".drag-item"
          >
            <li v-for="(item, index) in data.columns" :key="index">
              <i
                class="drag-item"
                style="font-size: 16px; margin: 0 5px; cursor: move"
                ><i class="fas fa-bars"></i
              ></i>
              <b-form-input
                :placeholder="$t('FORMS.CONFIG.WIDGET.SPAN')"
                style="width: 100px"
                type="number"
                v-model.number="item.span"
              ></b-form-input>
              <i
                @click="handleOptionsRemove(index)"
                class="fas fa-minus-circle text-danger ml-2"
              ></i>
            </li>
          </draggable>
          <div style="margin-left: 22px">
            <b-button variant="link" @click="handleAddColumn">{{
              $t("FORMS.ACTIONS.ADD_COLUMN")
            }}</b-button>
          </div>
        </b-form-group>
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY')">
          <b-form-select v-model="data.options.justify">
            <b-form-select-option
              value="start"
              :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY_START')"
            ></b-form-select-option>
            <b-form-select-option
              value="end"
              :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY_END')"
            ></b-form-select-option>
            <b-form-select-option
              value="center"
              :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY_CENTER')"
            ></b-form-select-option>
            <b-form-select-option
              value="space-around"
              :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY_SPACE_AROUND')"
            ></b-form-select-option>
            <b-form-select-option
              value="space-between"
              :label="$t('FORMS.CONFIG.WIDGET.JUSTIFY_SPACE_BETWEEN')"
            ></b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.ALIGN')">
          <b-form-select v-model="data.options.align">
            <b-form-select-option
              value="top"
              :label="$t('FORMS.CONFIG.WIDGET.ALIGN_TOP')"
            ></b-form-select-option>
            <b-form-select-option
              value="middle"
              :label="$t('FORMS.CONFIG.WIDGET.ALIGN_MIDDLE')"
            ></b-form-select-option>
            <b-form-select-option
              value="bottom"
              :label="$t('FORMS.CONFIG.WIDGET.ALIGN_BOTTOM')"
            ></b-form-select-option>
          </b-form-select>
        </b-form-group>
      </template>

      <template v-if="data.type === 'section'">
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.SECTION_TITLE')">
          <b-form-input v-model="data.options.title"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="data.options.collapsible"
            v-if="Object.keys(data.options).indexOf('collapsible') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.COLLAPSIBLE") }}</b-form-checkbox
          >
        </b-form-group>
      </template>

      <template v-if="!isLayoutWidget">
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.ATTRIBUTE')">
          <b-form-checkbox
            v-model="data.options.readonly"
            v-if="Object.keys(data.options).indexOf('readonly') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.READONLY") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-model="data.options.plaintext"
            v-if="data.type === 'textarea'"
            >{{ $t("FORMS.CONFIG.WIDGET.PLAINTEXT") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-model="data.options.disabled"
            v-if="Object.keys(data.options).indexOf('disabled') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.DISABLE") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-model="data.options.editable"
            v-if="Object.keys(data.options).indexOf('editable') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.EDITABLE") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="data.options.clearable"
            v-if="Object.keys(data.options).indexOf('clearable') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.CLEARABLE") }}
          </b-form-checkbox>
          <b-form-checkbox
            v-model="data.options.arrowControl"
            v-if="Object.keys(data.options).indexOf('arrowControl') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.ARROW_CONTROL") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="data.options.isDelete"
            v-if="Object.keys(data.options).indexOf('isDelete') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.IS_DELETE") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="data.options.isEdit"
            v-if="Object.keys(data.options).indexOf('isEdit') >= 0"
            >{{ $t("FORMS.CONFIG.WIDGET.IS_EDIT") }}</b-form-checkbox
          >
        </b-form-group>
        <b-form-group :label="$t('FORMS.CONFIG.WIDGET.VALIDATE')">
          <div v-if="Object.keys(data.options).indexOf('required') >= 0">
            <b-form-checkbox v-model="data.options.required">{{
              $t("FORMS.CONFIG.WIDGET.REQUIRED")
            }}</b-form-checkbox>
          </div>
          <b-form-select
            v-if="Object.keys(data.options).indexOf('dataType') >= 0"
            v-model="data.options.dataType"
            size="mini"
          >
            <b-form-select-option
              value="string"
              :label="$t('FORMS.CONFIG.WIDGET.STRING')"
            ></b-form-select-option>
            <b-form-select-option
              value="number"
              :label="$t('FORMS.CONFIG.WIDGET.NUMBER')"
            ></b-form-select-option>
            <b-form-select-option
              value="boolean"
              :label="$t('FORMS.CONFIG.WIDGET.BOOLEAN')"
            ></b-form-select-option>
            <b-form-select-option
              value="integer"
              :label="$t('FORMS.CONFIG.WIDGET.INTEGER')"
            ></b-form-select-option>
            <b-form-select-option
              value="float"
              :label="$t('FORMS.CONFIG.WIDGET.FLOAT')"
            ></b-form-select-option>
            <b-form-select-option
              value="url"
              :label="$t('FORMS.CONFIG.WIDGET.URL')"
            ></b-form-select-option>
            <b-form-select-option
              value="email"
              :label="$t('FORMS.CONFIG.WIDGET.EMAIL')"
            ></b-form-select-option>
            <b-form-select-option
              value="hex"
              :label="$t('FORMS.CONFIG.WIDGET.HEX')"
            ></b-form-select-option>
          </b-form-select>

          <div v-if="Object.keys(data.options).indexOf('pattern') >= 0">
            <b-form-input
              size="mini"
              v-model.lazy="data.options.pattern"
              style="width: 240px"
              :placeholder="$t('FORMS.CONFIG.WIDGET.PATTERN_PLACEHOLDER')"
            ></b-form-input>
          </div>
        </b-form-group>
      </template>
    </b-form>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { VueEditor } from "vue2-editor";

const FONT_SIZES = [
  {
    value: "font-size-base",
    text: "default",
  },
  {
    value: "font-size-h1",
    text: "h1",
  },
  {
    value: "font-size-h2",
    text: "h2",
  },
  {
    value: "font-size-h3",
    text: "h3",
  },
  {
    value: "font-size-h4",
    text: "h4",
  },
  {
    value: "font-size-h5",
    text: "h5",
  },
  {
    value: "font-size-h6",
    text: "h6",
  },
  {
    value: "display1",
    text: "display1",
  },
  {
    value: "display2",
    text: "display2",
  },
  {
    value: "display3",
    text: "display3",
  },
  {
    value: "display4",
    text: "display4",
  },
  {
    value: "display5",
    text: "display5",
  },
];

export default {
  components: {
    Draggable,
    VueEditor,
  },
  props: ["data"],
  data() {
    return {
      validator: {
        type: null,
        required: null,
        pattern: null,
        range: null,
        length: null,
      },
    };
  },
  computed: {
    show() {
      return this.data && Object.keys(this.data).length > 0;
    },
    isLayoutWidget() {
      return ["grid", "section"].includes(this.data.type);
    },
    isCustomComponent() {
      return !!this.data.isCustom;
    },
    fontSizeList() {
      return FONT_SIZES;
    },
  },
  methods: {
    handleOptionsRemove(index) {
      if (this.data.type === "grid") {
        this.data.columns.splice(index, 1);
      } else {
        this.data.options.options.splice(index, 1);
      }
    },
    handleAddOption() {
      if (this.data.options.showLabel) {
        this.data.options.options.push({
          value: this.$t("FORMS.CONFIG.WIDGET.NEW_OPTION"),
          label: this.$t("FORMS.CONFIG.WIDGET.NEW_OPTION"),
        });
      } else {
        this.data.options.options.push({
          value: this.$t("FORMS.CONFIG.WIDGET.NEW_OPTION"),
        });
      }
    },
    handleAddColumn() {
      this.data.columns.push({
        span: "",
        list: [],
      });
    },
    generateRule() {
      this.data.rules = [];
      Object.keys(this.validator).forEach((key) => {
        if (this.validator[key]) {
          this.data.rules.push(this.validator[key]);
        }
      });
    },

    validateRequired(val) {
      if (val) {
        this.validator.required = {
          required: true,
          message: `${this.$t(
            "VALIDATION.REQUIRED_FIELD"
          )}`,
        };
      } else {
        this.validator.required = null;
      }

      this.$nextTick(() => {
        this.generateRule();
      });
    },

    validateDataType(val) {
      if (!this.show) {
        return false;
      }

      if (val) {
        this.validator.type = {
          type: val,
          message:
            this.data.name + this.$t("FORMS.CONFIG.WIDGET.VALIDATOR_TYPE"),
        };
      } else {
        this.validator.type = null;
      }

      this.generateRule();
    },
    valiatePattern(val) {
      if (!this.show) {
        return false;
      }

      if (val) {
        this.validator.pattern = {
          pattern: val,
          message:
            this.data.name + this.$t("FORMS.CONFIG.WIDGET.VALIDATOR_PATTERN"),
        };
      } else {
        this.validator.pattern = null;
      }

      this.generateRule();
    },
  },
  watch: {
    "data.options.required": function (val) {
      this.validateRequired(val);
    },
    "data.name": function (val) {
      if (this.data.options) {
        this.validateRequired(this.data.options.required);
        this.validateDataType(this.data.options.dataType);
        this.valiatePattern(this.data.options.pattern);
      }
    },
  },
};
</script>
