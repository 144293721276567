export const basicComponents = [
  {
    type: 'input',
    icon: 'far fa-keyboard',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      dataType: 'string',
      pattern: '',
      placeholder: '',
      disabled: false,
      hideLabel: false,
      showTooltip: false
    }
  },
  {
    type: 'textarea',
    icon: 'fas fa-align-left',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      disabled: false,
      pattern: '',
      placeholder: '',
      hideLabel: false,
      rows: '',
      maxRows: '',
      plaintext: false,
    }
  },
  {
    type: 'number',
    icon: 'fas fa-sort-numeric-up-alt',
    options: {
      width: '',
      required: false,
      defaultValue: 0,
      min: '',
      max: '',
      step: 1,
      disabled: false,
      controlsPosition: '',
      hideLabel: false,
    }
  },
  {
    type: 'radio',
    icon: 'far fa-dot-circle',
    options: {
      inline: false,
      defaultValue: '',
      showLabel: false,
      options: [
        {
          value: 'Option 1',
          label: 'Option 1'
        },
        {
          value: 'Option 2',
          label: 'Option 2'
        },
        {
          value: 'Option 3',
          label: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false,
      hideLabel: false,
    }
  },
  {
    type: 'checkbox',
    icon: 'far fa-check-square',
    options: {
      inline: false,
      defaultValue: [],
      showLabel: false,
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },
        {
          value: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false,
      hideLabel: false,
    }
  },
  {
    type: 'date',
    icon: 'far fa-calendar-alt',
    options: {
      defaultValue: '',
      readonly: false,
      disabled: false,
      editable: true,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      type: 'date',
      format: 'yyyy-MM-dd',
      timestamp: false,
      required: false,
      width: '',
      hideLabel: false,
    }
  },
  {
    type: 'select',
    icon: 'far fa-check-circle',
    options: {
      defaultValue: '',
      multiple: false,
      disabled: false,
      clearable: false,
      placeholder: '',
      required: false,
      showLabel: false,
      width: '',
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },{
          value: 'Option 3'
        }
      ],
      remote: false,
      filterable: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      hideLabel: false,
    }
  },
  {
    type: 'switch',
    icon: 'fas fa-toggle-on',
    options: {
      defaultValue: false,
      required: false,
      disabled: false,
      hideLabel: false,
    },
  },
  {
    type: 'text',
    icon: 'fas fa-font',
    options: {
      defaultValue: 'This is a text',
      customClass: '',
      isBold: false,
      fontSize: '12',
      hideLabel: false,
    },
  },
  {
    type: 'html',
    icon: 'fab fa-html5',
    options: {
      defaultValue: '',
      width: 100,
      hideLabel: false,
    },
  },
  {
    type: 'divider',
    icon: 'fas fa-minus',
    options: {
      customClass: '',
    }
  }
]

export const advanceComponents = [
  {
    type: 'imgupload',
    icon: 'far fa-file-image',
    options: {
      defaultValue: [],
      size: {
        width: 100,
        height: 100,
      },
      width: '',
      tokenFunc: 'funcGetToken',
      token: '',
      domain: 'http://pfp81ptt6.bkt.clouddn.com/',
      disabled: false,
      length: 8,
      multiple: false,
      isQiniu: false,
      isDelete: false,
      min: 0,
      isEdit: false,
      action: 'https://jsonplaceholder.typicode.com/photos/'
    }
  },
  {
    type: 'file',
    icon: 'far fa-file',
    options: {
      defaultValue: [],
      size: {
        width: 100,
        height: 100,
      },
      width: '',
      tokenFunc: 'funcGetToken',
      token: '',
      domain: 'http://pfp81ptt6.bkt.clouddn.com/',
      disabled: false,
      length: 8,
      multiple: false,
      isQiniu: false,
      isDelete: false,
      min: 0,
      isEdit: false,
      action: 'https://jsonplaceholder.typicode.com/photos/'
    }
  },
  {
    type: 'editor',
    icon: 'fas fa-edit',
    options: {
      defaultValue: '',
      width: ''
    }
  },
]

export const layoutComponents = [
  {
    type: 'grid',
    icon: 'fas fa-grip-horizontal',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      gutter: 0,
      justify: 'start',
      align: 'top'
    }
  },
  {
    type: 'section',
    icon: 'far fa-window-maximize',
    options: {
      collapsible: false,
      title: 'Section'
    },
    list: [],
  },
  {
    type: 'inline',
    icon: 'fas fa-columns',
    options: {
      title: 'Inline'
    },
    list: [],
  },
]

export const customComponents = [
  {
    type: 'beneficiary-list',
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
  {
    type: 'real-estate-list',
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
  {
    type: 'applicant-form',
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
  {
    type: 'decision-list',
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
  {
    type: 'attachments',
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
  {
    type: 'urbanism-regulation-picker', // Urbanism regulations picker
    icon: 'fas fa-puzzle-piece',
    isCustom: true,
  },
]
